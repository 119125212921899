<template>
  <div class="about-page">
    <common-header></common-header>
    <div class="about-content">
      <!-- banner图片 -->
      <div class="about-banner">
        <img class="banner-font" :src="$http + '/en/about/bannerFont.png'" alt="图片文字" />
      </div>
      <!-- 团队介绍 -->
      <div class="about-team">
        <el-image class="about-title-img" :src="require('../../assets/en/about/team.png')" />
        <div class="about-introduce">
          <div class="about-info" v-for="item in teamList" :key="item.nameUrl">
            <el-image class="user-img" :src="item.nameUrl" />
            <el-image class="user-type" :src="item.typeUrl" />
            <span class="user-name">{{item.name}}</span>
            <el-image class="user-back" :src="require('../../assets/zh/about/teamBack.png')" />
          </div>
        </div>
      </div>
      <!-- 合作内容 -->
      <div class="team-box">
        <div class="team-text">
          <div>Chen Qinyang renowned music producer, arranger from Mainland China, instructor of Arranging Masterclasses of Department of Music Technology, Beijing Academy of Contemporary Music, music producer of NetEase Cloud Music “Rock Plan”Season 1 and Season 2, member of the Associate of Chinese Popular Musicians, endorsed and cooperated artist with Jamstick Guitar. He was appointed as a member of the professional jury of the final of “Voice of China”for four consecutive years from 2019 to 2022. </div>
          <div>Representative Work: Jackie Chan ( Ambition Lies in My Chest 2022), Zhang Jie ( Light in the Heart ) Li Yuchun ( Wing Chun)Xiao Jingteng / Zhang Qi ( Wujiapo 2021 ), Yuan Yawei ( Coming For You ), Zhang Bichen ( Listening to the Snow ) etc.</div>
          <div>Corperated Artist: Jackie Chan, Huang Bo, Wang Yibo, Li Yuchun, Zhang Jie, Zhang Bichen, Tenggeer, Yuan Yawei, Yu Kewei etc.</div>
        </div>
      </div>
      <!-- 发展历程 -->
      <div class="about-development">
        <el-image class="about-title-img" :src="require('../../assets/en/about/development.png')" />
        <div class="development-introduce">
          <div class="introduce-info">
            <el-image class="introduce-year" style="margin-bottom:24px" :src="require('@/assets/zh/about/2021.png')" />
            <span class="introduce-name" style="margin-bottom: 24px">Start The Project.</span>
            <el-image class="introduce-img1" :src="require('@/assets/zh/about/2021Pic.png')" />
          </div>
          <div class="introduce-info">
            <el-image class="introduce-img2" :src="require('@/assets/zh/about/2022Pic.png')" />
            <el-image class="introduce-year" :src="require('@/assets/zh/about/2022.png')" style="margin-top:24px" />
            <span class="introduce-name" style="margin-top:24px">Start Sampling. </span>
          </div>
          <div class="introduce-info">
            <el-image class="introduce-cd" :src="require('@/assets/zh/about/2023CD.png')" />
            <el-image class="introduce-special-img" :src="require('@/assets/zh/about/2023Pic.jpg')" />
            <el-image class="introduce-year" :src="require('@/assets/zh/about/2023.png')"  style="margin-top:24px" />
            <span class="introduce-name"  style="margin-top:24px">Editing , Processing Samples , Global Release. </span>
          </div>
        </div>
      </div>
      <!-- 最新动态 -->
      <div class="about-news">
        <el-image class="about-title-img" :src="require('@/assets/en/about/news.png')" />
        <el-image class="about-title" :src="require('@/assets/en/about/newsFont.png')" />
        <div class="news-banner">
          <el-image class="new-banner" :src="require('@/assets/zh/about/newsBanner.png')" />
          <div class="news-font">
            <div class="new-font">
              <div>Qin Strings Global Launch Conference will be held on January 18th at Dingdong Audio. In the Meantime, numbers of elites from the music industry and professors from multiple music conservatories from all around China, as well as the behind - screen production team will all attend this event.</div>
              <div>During the Product Launch Conference, Mr Chen Qinyang will introduce the design, the use of this software in detail along with some back - the - scenes stories during the creation process of Qin Strings.</div>
              <div>On the day of Product Launch Conference, both teachers and students will be able to purchase these sample libraries at a special price. </div>
              <div>Scan the QR code below, launch the mini - App “Ding Dong Audio” on Wechat and click on “Recent Courses/Salon” to make an appointment and participate this event.</div>
            </div>
            <el-image class="news-code" :src="require('@/assets/zh/about/newsCode.png')" />
          </div>
        </div>
      </div>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import commonFooter from "../../components/en/commonFooter.vue";
import commonHeader from "../../components/en/commonHeader.vue";
export default {
  components: {
    commonHeader,
    commonFooter,
  },
  data(){
    return{
      // 团队信息列表
      teamList: [
        {
          name: 'Jeff Chan',
          nameUrl: this.$http + '/zh/about/teamChen.png',
          typeUrl: require('@/assets/en/about/creater.png')
        },
        {
          name: 'Jintian Recording Studio XiaoFour Wang',
          nameUrl: this.$http + '/zh/about/teamWang.png',
          typeUrl: require('@/assets/en/about/recordingEngineer.png')
        },
        {
          name: 'International Chief Philaharmonic Orchestra',
          nameUrl: this.$http + '/zh/about/teamMusic.png',
          typeUrl: require('@/assets/en/about/stringOrchestra.png')
        }
      ]
    }
  }
};
</script>

<style scoped lang='scss'>
.about-page {
  width: 100%;
  height: 100%;
  .about-content{
    width: 100%;
    height: 100%;
    // 顶部banner
    .about-banner {
      padding-top: 90px;
      width: 100%;
      height: 814px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: url("../../assets/zh/about/banner.jpg");
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
      .banner-font {
        width: 1100px;
        height: 200px;
        margin-top: 235px;
      }
    }
    // 团队介绍
    .about-team{
      width: 100%;
      background: #000000;
      // padding-bottom: 97px;
      .about-title-img{
        margin: 80px 0 86px 270px;
        width: 696px;
        height: 152px;
      }
      .about-introduce{
        margin-left: 88px;
        padding: 0 255px 0 168px;
        display: flex;
        justify-content: space-between;
        .about-info{
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          &:first-child {
            .user-type{
              margin: 33px 0 72px 0;
              width: 174px;
              height: 36px;
            }
          }
          &:nth-child(2) {
            .user-type{
              margin: 33px 0 24px 0;
              width: 422px;
              height: 84px;
            }
          }
          &:nth-child(3) {
            .user-type{
              margin: 33px 0 72px 0;
              width: 338px;
              height: 36px;
            }
          }
          .user-img{
            width: 391px;
            height: 544px;
          }
          .user-name{
            width: 381px;
            text-align: center;
            font-size: 28px;
            font-family: PingFang SC;
            color: #fff;
            margin-bottom: 70px;
          }
          .user-back{
            position: absolute;
            left: -80px;
            bottom: 240px;
            width: 293px;
            height: 163px;
          }
        }
      }
    }
    // 合作内容
    .team-box{
      width: 100%;
      padding: 37px 0 37px 0;
      background: url('../../assets/zh/about/teamFont.png') no-repeat, black;
      .team-text{
        width: 1400px;
        height: 432px;
        color: #fff;
        font-size: 24px;
        margin: 0px 0 0 301px;
        div{
          line-height: 48px;
          text-indent: 2em;
          // text-align: justify;
        }
      }
    }
    // 发展历程
    .about-development{
      width: 100%;
      padding-bottom: 32px;
      background: url('../../assets/zh/about/developmentBack.png') no-repeat,black;
      .about-title-img{
        margin: 80px 0 22px 270px;
        width: 696px;
        height: 152px;
      }
      .development-introduce{
        margin: 0 210px 0px 270px;
        display: flex;
        justify-content: space-between;
        .introduce-info{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .introduce-name{
            font-size: 28px;
            font-family: PingFang SC;
            color: #FFFFFF;
          }
          .introduce-year{
            width: 145px; 
            height: 47px;
          }
          .introduce-cd{
            width: 483px;
            height: 269px;
            transform: translate(47px, 50px);
          }
          .introduce-img1{
            width: 366px;
            height: 564px;
          }
          .introduce-img2{
            width: 366px;
            height: 504px;
          }
          .introduce-special-img{
            width: 582px;
            height: 328px;
          }
        }
      }
    }
    // 最新动态
    .about-news{
      width: 100%;
      background: url('../../assets/zh/about/newsBack.png') no-repeat, black;
      padding: 0 252px 140px 264px;
      box-sizing: border-box;
      .about-title-img{
        margin: 84px 0 56px 0px;
        width: 845px;
        height: 168px;
      }
      .about-title{
        display: block; 
        margin-left: 283px;
        width: 845px;
        height: 168px;
      }
      .news-banner{
        display: flex;
        justify-content: space-between;
        margin-top: 78px;
        .new-banner{
          width: 472px;
          height: 774px;
        }
        .news-font{
          // margin-top: 24px;
          width: 810px;
          .new-font{
            line-height: 48px;
            color: #fff;
            font-size: 24px;
            text-indent: 2em;
            // text-align: justify;
            &>div:last-child {
              width: 560px;
              height: 193px;
            }
          }
          .news-code{
            margin-top: -180px;
            float: right;
            width: 180px;
            height: 181px;
          }
        }
      }
    }
  }
}
</style>